<template>
  <div>
    <!-- <Tips/> -->
    <van-nav-bar class="question-title" fixed left-arrow >
      <template #left>
        <van-button color="#ffffff" @click="handleClickProject">{{groupName}}
          <van-icon name="play" />
        </van-button>
      </template>
      <template #right>
        <van-grid>
          <!-- <van-grid-item icon="search" text="搜索" @click="handleClickSearch" /> -->
          <van-grid-item icon="chat-o" text="客服" />
        </van-grid>
      </template>
    </van-nav-bar>
    <transition name="van-slide-right" mode="out-in">
      <router-view></router-view>
    </transition>

    <div class="question-content">
      <div class="banner-index" v-if="banner.length > 0">
        <van-swipe class="my-swipe" :autoplay="3000" :height="120" indicator-color="#1989fa">
          <van-swipe-item v-for="item in banner" :key="item.id">
            <img :src="item.url" width="100%" style="display: block;" alt />
          </van-swipe-item>
        </van-swipe>
      </div>
      <div class="question-navigation">
        <van-grid :column-num="5">
          <van-grid-item v-for="(item, index) in questionNavigation" :key="index" :text="item.text" :icon="item.photo" />
          {{ item }}
        </van-grid>
      </div>
      <div v-if="isWx">
        <wx-open-launch-weapp id="launch-btn" username="gh_f5ebaee32697" :path="url" env-version="release">
          <script type="text/wxtag-template">
            <style>.btn { padding:0rem 1rem; color:#ffffff;font-size:1.6rem;background-color:#1989fa;width:90%;height:auto;border-radius:5rem;text-align:center; }</style>
            <button class="btn van-button van-button--info van-button--large van-button--round">点击进入小程序题库</button>
          </script>
        </wx-open-launch-weapp>
        <!-- <wx-open-launch-weapp id="launch-btn" username="gh_5ea431644090" :path="url" env-version="release">
          <script type="text/wxtag-template">
            <style>.btn {  padding:0rem 1rem; color:#ffffff;font-size:1.8rem;background-color:#1989fa;width:16rem;height:6rem;border-radius:5rem;}</style>
            <button class="btn van-button van-button--info van-button--large van-button--round">打开小程序</button>
          </script>
        </wx-open-launch-weapp> -->
      </div>
      <div style="text-align:center;width: 90%;margin: 0 auto;" v-else>
        <van-button round center size="large" type="info" @click="clickMsg">点击进入小程序题库</van-button>
      </div>
    </div>






  </div>
</template>

<script>
  import {onShareUrl, questionBanner} from '@/request/api'
  import {Toast} from "vant";
  import wx from "weixin-js-sdk";
export default {
  name: 'Question',
  // metaInfo: {
  //   title: '题库', //设置页面title
  //   meta: [{                 //设置页面meta
  //     name: 'keyWords',
  //     content: '关键词'
  //   },
  //   {
  //     name: "description",
  //     content: "描述",
  //   },
  //   ]
  // },
  metaInfo() {
    return {
      title: this.title+'-题库', //设置页面title
    }
  },
  data() {
    return {
      title: this.getBranchCompany(),
      show: false,
      item:"",
      //轮播图数组
      banner: [],

      questionNavigation: [
        {
          text: "做题记录",
          icon: "n",
          photo: "https://cdnoss.ksyt.com.cn/wxImages/assets/images/icon_jilu.png"
        },
        {
          text: "错题集",
          icon: "w",
          photo: "https://cdnoss.ksyt.com.cn/wxImages/assets/images/icon_cuoti.png"
        },
        {
          text: "收藏夹",
          icon: "j",
          photo: "https://cdnoss.ksyt.com.cn/wxImages/assets/images/icon_shoucang.png"
        },
        {
          text: "题库答疑",
          icon: "g",
          photo: "https://cdnoss.ksyt.com.cn/wxImages/assets/images/icon_dayi.png"
        },
        {
          text: "做题报告",
          icon: "h",
          photo: "https://cdnoss.ksyt.com.cn/wxImages/assets/images/icon_baogao.png"
        },
      ],
      
      productList:[
            {
                goods_tag:"推荐",
                goods_title:"2022年中药全科（模考班）",
                goods_desc:"导学班+精讲班+强化班+真题班+习题班+点睛班",
                goods_thumb:"https://cdnoss.ksyt.com.cn/weixin/banci.png",
                goods_price:980.00
            },
            {
                goods_tag:"推荐",
                goods_title:"2022年西药全科（模考班）",
                goods_desc:"导学班+精讲班+强化班+真题班+习题班+点睛班",
                goods_thumb:"https://cdnoss.ksyt.com.cn/weixin/banci.png",
                goods_price:980.00
            }  
      ],
      isWx: this.isWeiXin(),
      url: 'pages/index/index?branchCenter='+this.getBranch()+'&adminIdCenter='+this.getAdminId()+'&groupCenter='+this.getGroupId()+'&subjectCenter='+this.getSubjectId(),
      groupName: (localStorage.getItem("groupName") ? localStorage.getItem("groupName") : '未选择'),
    }
  },
  created() {
    this.bannerList();
    this.onWxConfig();
  },

  methods: {
    handleClickProject() {
      this.$router.push({
        name: 'Project',
        query: {
          redirect: (this.$route.name)
        }
      })
      // this.$router.push('/question/project')
      // 修改vuex中的 isShowPopupShow 值为true
      // this.$store.commit("show", true)
      // console.log("搜索按钮被点击");
    },

    handleClickSearch() {
      this.$router.push('/question/searchPopup')
      // 修改vuex中的 isShowPopupShow 值为true
      // this.$store.commit("changeIsShowPopupShow", true)
      // console.log("搜索按钮被点击");
    },

    courseMore() {
      this.$router.push('/course')
      // 修改vuex中的 isShowPopupShow 值为true
      // this.$store.commit("changeIsShowPopupShow", true)
      // console.log("搜索按钮被点击");
    },
    handleClickCourseDetail() {
      this.$router.push('/courseDetail')
      // 修改vuex中的 isShowPopupShow 值为true
      // this.$store.commit("changeIsShowPopupShow", true)
      // console.log("搜索按钮被点击");
    },
    handler(link) {
      this[link]()
    },
    handleClickType() {
      this.$router.push('/questionList')
      // 修改vuex中的 isShowPopupShow 值为true
      // this.$store.commit("changeIsShowPopupShow", true)
      // console.log("搜索按钮被点击");
    },
    handleClickExam() {
      this.$router.push('/Zxks')
      // 修改vuex中的 isShowPopupShow 值为true
      // this.$store.commit("changeIsShowPopupShow", true)
      // console.log("搜索按钮被点击");
    },
    handleCalculator() {
      this.$router.push('/calculator')
      // 修改vuex中的 isShowPopupShow 值为true
      // this.$store.commit("changeIsShowPopupShow", true)
      // console.log("搜索按钮被点击");
    },
    bannerList() {
      let branchId = this.getBranch();
      let that = this;
      questionBanner({branchId}).then(res => {
        if (res.data.status == 1) {
          that.banner = res.data.data.data;
        }
      })
    },
    clickMsg() {
      Toast.success('请微信搜索“社工学习助手”小程序');
    },
    onWxConfig() {
      onShareUrl({url: location.href.split('#')[0]})
              .then(res => {
                if (res.data.code == 1) {
                  wx.config({
                    debug: false,// 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                    appId: res.data.data.sdk.appId,         // 必填，公众号的唯一标识，填自己的！
                    timestamp: res.data.data.sdk.timestamp, // 必填，生成签名的时间戳，刚才接口拿到的数据
                    nonceStr: res.data.data.sdk.nonceStr,   // 必填，生成签名的随机串
                    signature: res.data.data.sdk.signature, // 必填，签名，见附录1
                    jsApiList: [
                      'onMenuShareTimeline',
                      'onMenuShareAppMessage',
                      'wx-open-launch-weapp'
                    ],
                    openTagList: ['wx-open-launch-weapp']
                  })
                  let that = this;
                  wx.ready(function () {
// that.getHtml();
                    //分享到朋友圈
                    wx.onMenuShareTimeline({
                      title:  that.title,   // 分享时的标题
                      link: location.href,     // 分享时的链接
                      desc: '高清视频，同步字幕，名师精讲，像看电影一样的学习体验，助你轻松备考！',
                      imgUrl: that.photopath,    // 分享时的图标
                      success: function () {
                        console.log("分享成功");
                      },
                      cancel: function () {
                        console.log("取消分享");
                      }
                    });
                    //分享给朋友
                    wx.onMenuShareAppMessage({
                      title:  that.title,
                      desc: '高清视频，同步字幕，名师精讲，像看电影一样的学习体验，助你轻松备考！',
                      link: location.href,
                      imgUrl: that.photopath,
                      type: '',
                      dataUrl: '',
                      success: function () {
                        console.log("分享成功");
                      },
                      cancel: function () {
                        console.log("取消分享");
                      }
                    });
                  });
                  wx.error(function(res){
                    console.log(123);
                    console.log(res);
                    // config信息验证失败会执行 error 函数，如签名过期导致验证失败，具体错误信息可以打开 config 的debug模式查看，也可以在返回的 res 参数中查看，对于 SPA 可以在这里更新签名。
                  })
                }
              })
              .catch(err => {
                console.log("error");
                console.log(err);
              })
    },
  },
  components: {

  },
  computed: {

  },
}
</script>
 
<style lang = "less">
.question-title .van-nav-bar__content .van-nav-bar__left .van-button__text {
  font-size: 2.2rem !important;
  font-weight: 600 !important;
  color: #333333 !important;
}

.question-title .van-nav-bar__content .van-nav-bar__left .van-icon {
  font-size: 2.2rem !important;
  font-weight: 600;
  color: #333333;
  -moz-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
}

.question-title .van-nav-bar__content .van-nav-bar__right .van-icon {
  font-size: 2.8rem !important;
}

.question-title .van-nav-bar__content .van-nav-bar__right .van-grid-item__text {
  font-size: 1.6rem !important;
}

.question-title .van-nav-bar__content .van-nav-bar__right {
  padding: 0 0.5rem !important;
}

.question-title .van-nav-bar__content .van-nav-bar__right .van-grid-item__content {
  width: 6rem !important;
}

.question-content {
  padding: 6rem 0 7rem 0;
  height: 100%;
  background-color: #ffffff;
}

.question-navigation .van-grid {
  margin: 1rem 0;
}

.question-navigation .van-hairline--top::after {
  border-width: 0;
}

.question-navigation .van-grid-item__content {
  padding: .8rem;
}

.question-navigation .van-grid-item__content::after {
  border-width: 0;
}

.question-navigation .van-grid .van-grid-item .van-icon {
  font-size: 5rem;
}

.question-navigation .van-grid .van-grid-item .van-grid-item__text {
  font-size: 1.6rem;
  margin-top: .2rem;
}


.question-type .van-grid {
  margin: 1rem;
}

.question-type .van-hairline--top::after {
  border-width: 0;
}

.question-type .van-grid-item__content {
  padding: 1rem 0.7rem 0.4rem 0.7rem;
}

.question-type .van-grid-item__content::after {
  border-width: 0;
}

.question-type .van-grid .van-grid-item .typeList {
  background-color: #eef5ff;
  border-radius: 1.5rem;
  padding: 1.5rem;
}

.question-type .van-grid .van-grid-item .typeList .van-icon {
  font-size: 4rem;
  padding-left: 0.4rem;
}
.question-type .van-grid .van-grid-item .typeList .van-tag {
    position: absolute;
    padding: 0.1rem 0.8rem !important;
    top: 0.1rem;
    right: 0;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 1.4rem;
}
.question-type .van-grid .van-grid-item .typeList .van-cell__label {
  line-height: 3rem;
  font-size: 1.6rem;
}

.question-product {
  margin: 1rem;
}
.question-product .title{
  padding: 0 1rem;
}
.question-product .title::after {
  border-width: 0;
}
.question-product .title .van-cell__title {
  font-weight: 600;
  font-size: 2.4rem;
}
.question-product .title .van-cell__value {
  line-height: 4rem;
  font-size: 1.8rem;
}
.question-product .title .van-icon{
  font-size: 2.2rem;
}



</style>